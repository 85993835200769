
const purple = {
  "dark-text-color": "white",
  "dark-scrollbar-color": "#0a020c; // 33008",
  "dark-background-color": "#2b233b",
  "dark-border-color": "#151550",
  "dark-modal-background": "#222232",
  "dark-input-color": "#4c4c70",
  "dark-card-color": "#b4b4b4",
  "dark-card-shadow": "rgba(22, 0, 22, 0.2)",
  "dark-gradient-1": "#6699f7",
  "dark-gradient-2": "#be2abe",
  "dark-table-head": "rgba(22, 0, 22, 0.3)",
  "dark-table-hover": "rgba(22, 0, 22, 0.2)",
}

export default purple
