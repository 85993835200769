
const darkBlue = {
  "dark-text-color": "#b2b2b2",
  "dark-scrollbar-color": "#02020c; // 33008",
  "dark-background-color": "#101020",
  "dark-border-color": "#151550",
  "dark-modal-background": "#222232",
  "dark-input-color": "#4c4c70",
  "dark-card-color": "#b4b4b4",
  "dark-card-shadow": "rgba(0, 0, 0, 0.5)",
  "dark-gradient-1": "#d53369",
  "dark-gradient-2": "#daae51",
  "dark-table-head": "rgba(0, 0, 0, 0.2)",
  "dark-table-hover": "rgba(50, 0, 200, 0.4)",
}

export default darkBlue
