
const dark = {
  "dark-text-color": "#ffffff",
  "dark-scrollbar-color": "#19191b",
  "dark-background-color": "#252525",
  "dark-border-color": "#17171d",
  "dark-modal-background": "#222222",
  "dark-input-color": "#4c4c70",
  "dark-card-color": "#b4b4b4",
  "dark-card-shadow": "rgba(0, 0, 0, 0.2)",
  "dark-gradient-1": "#67b657",
  "dark-gradient-2": "#5d73f0",
  "dark-table-head": "#201e1e",
  "dark-table-hover": "rgba(50, 0, 200, 0.4)",
}

export default dark
