
const light = {
  "dark-text-color": "#6d4c44",
  "dark-scrollbar-color": "#faba43",
  "dark-background-color": "#fff",
  "dark-border-color": "#faba43",
  "dark-modal-background": "#ffeed1",
  "dark-input-color": "#4c4c70",
  "dark-card-color": "#6d4c44",
  "dark-card-shadow": "#ffeed160",
  "dark-gradient-1": "#98dac6",
  "dark-gradient-2": "#e4d8be",
  "dark-table-head": "#fff7e7",
  "dark-table-hover": "fff7e750",
}

export default light
