
import _ from 'lodash'
import { CapitalWeightings, CreditLine, RiskWeightings, Trader } from "./types"

export const traders:Trader[] = _.sampleSize([
  {name: "SVPHOUSING", risk: RiskWeightings.xxs},
  {name: "ACEWIN", risk: RiskWeightings.xxs},
  {name: "DML", risk: RiskWeightings.xxs},
  {name: "OBCL", risk: RiskWeightings.xxs},
  {name: "STL", risk: RiskWeightings.xxs},
  {name: "HKG", risk: RiskWeightings.xxs},
  {name: "SUNRETAIL", risk: RiskWeightings.xxs},
  {name: "NSL", risk: RiskWeightings.xxs},
  {name: "ANUROOP", risk: RiskWeightings.xxs},
  {name: "UHZAVERI", risk: RiskWeightings.xxs},
  {name: "AKSHAR", risk: RiskWeightings.xxs},
  {name: "MRSS", risk: RiskWeightings.xxs},
  {name: "AFFORDABLE", risk: RiskWeightings.xxs},
  {name: "MRCEXIM", risk: RiskWeightings.xs},
  {name: "SBC", risk: RiskWeightings.xs},
  {name: "KPL", risk: RiskWeightings.xs},
  {name: "VIVIDM", risk: RiskWeightings.xs},
  {name: "ANGEL", risk: RiskWeightings.xs},
  {name: "STELLAR", risk: RiskWeightings.xs},
  {name: "KDML", risk: RiskWeightings.xs},
  {name: "INNOVATORS", risk: RiskWeightings.xs},
  {name: "AAPLUSTRAD", risk: RiskWeightings.xs},
  {name: "VMV", risk: RiskWeightings.xs},
  {name: "SKL", risk: RiskWeightings.xs},
  {name: "MILEFUR", risk: RiskWeightings.xs},
  {name: "RADHIKAJWE", risk: RiskWeightings.s},
  {name: "RAJNISH", risk: RiskWeightings.s},
  {name: "FOCUS", risk: RiskWeightings.s},
  {name: "JINAAM", risk: RiskWeightings.s},
  {name: "JETMALL", risk: RiskWeightings.s},
  {name: "BENARA", risk: RiskWeightings.s},
  {name: "BILLWIN", risk: RiskWeightings.s},
  {name: "RCAN", risk: RiskWeightings.s},
  {name: "AML", risk: RiskWeightings.s},
  {name: "AXITA", risk: RiskWeightings.s},
  {name: "DECCAN", risk: RiskWeightings.s},
  {name: "GOBLIN", risk: RiskWeightings.s},
  {name: "NGIL", risk: RiskWeightings.s},
  {name: "INNOVATIVE", risk: RiskWeightings.s},
  {name: "ANG", risk: RiskWeightings.m},
  {name: "CHEMCRUX", risk: RiskWeightings.m},
  {name: "IRIS", risk: RiskWeightings.m},
  {name: "ADESHWAR", risk: RiskWeightings.m},
  {name: "RJBIOTECH", risk: RiskWeightings.m},
  {name: "GCMSECU", risk: RiskWeightings.m},
  {name: "SPICY", risk: RiskWeightings.m},
  {name: "UNISHIRE", risk: RiskWeightings.m},
  {name: "RITCO", risk: RiskWeightings.l},
  {name: "PECOS", risk: RiskWeightings.l},
  {name: "VGIL", risk: RiskWeightings.l},
  {name: "TIMESGREEN", risk: RiskWeightings.l},
  {name: "ARL", risk: RiskWeightings.l},
  {name: "DGL", risk: RiskWeightings.l},
  {name: "EKI", risk: RiskWeightings.l},
  {name: "WAA", risk: RiskWeightings.l},
  {name: "AAL", risk: RiskWeightings.l},
  {name: "EARUM", risk: RiskWeightings.l},
  {name: "GALACTICO", risk: RiskWeightings.l},
  {name: "CAPPIPES", risk: RiskWeightings.l},
  {name: "NAVODAYENT", risk: RiskWeightings.l},
  {name: "STARLENT", risk: RiskWeightings.l},
  {name: "MASL", risk: RiskWeightings.l},
  {name: "VANTABIO", risk: RiskWeightings.l},
  {name: "EVANS", risk: RiskWeightings.l},
  {name: "ADVAIT", risk: RiskWeightings.l},
  {name: "AARTECH", risk: RiskWeightings.l},
  {name: "JIGAR", risk: RiskWeightings.xl},
  {name: "DHRUV", risk: RiskWeightings.xl},
  {name: "POLYMAC", risk: RiskWeightings.xl},
  {name: "IGRL", risk: RiskWeightings.xl},
  {name: "BMAL", risk: RiskWeightings.xl},
  {name: "KRANTI", risk: RiskWeightings.xl},
  {name: "DJML", risk: RiskWeightings.xl},
  {name: "RONI", risk: RiskWeightings.xl},
  {name: "KMEW", risk: RiskWeightings.xl},
  {name: "RELICAB", risk: RiskWeightings.xl},
  {name: "RSTL", risk: RiskWeightings.xl},
  {name: "JONJUA", risk: RiskWeightings.xl},
  {name: "DRA", risk: RiskWeightings.xxl},
  {name: "SCPL", risk: RiskWeightings.xxl},
  {name: "MEERA", risk: RiskWeightings.xxl},
  {name: "ADVITIYA", risk: RiskWeightings.xxl},
  {name: "RMC", risk: RiskWeightings.xxl},
  {name: "JFL", risk: RiskWeightings.xxl},
  {name: "RACE", risk: RiskWeightings.xxl},
  {name: "RTL", risk: RiskWeightings.xxl},
  {name: "JANUSCORP", risk: RiskWeightings.xxl},
  {name: "INDOUS", risk: RiskWeightings.xxl},
  {name: "INFLAME", risk: RiskWeightings.xxl},
  {name: "ACML", risk: RiskWeightings.xxl},
  {name: "VIDLI", risk: RiskWeightings.xxl},
  {name: "SUPERSHAKT", risk: RiskWeightings.xxl},
], window.ntraders)

export const lenders:CreditLine[] = _.sampleSize([
  {lender: "Progcap", revenueMultiple: 0.3, collateral: 0.3, overcollateralMultiple: 3, interest: 18, utilised: 0, risk: CapitalWeightings.l},
  {lender: "Mintifi", revenueMultiple: 0.3, collateral: 0.3, overcollateralMultiple: 3, interest: 18, utilised: 0, risk: CapitalWeightings.l},
  {lender: "Apollo Finvest", revenueMultiple: 0.3, collateral: 0.3, overcollateralMultiple: 3, interest: 18, utilised: 0, risk: CapitalWeightings.l},
  {lender: "OkCredit", revenueMultiple: 0.3, collateral: 0.3, overcollateralMultiple: 3, interest: 18, utilised: 0, risk: CapitalWeightings.l},
  {lender: "Loantap", revenueMultiple: 0.25, collateral: 0.4, overcollateralMultiple: 2, interest: 14, utilised: 0, risk: CapitalWeightings.m},
  {lender: "Indifi", revenueMultiple: 0.25, collateral: 0.4, overcollateralMultiple: 2, interest: 14, utilised: 0, risk: CapitalWeightings.m},
  {lender: "Credavenue", revenueMultiple: 0.25, collateral: 0.4, overcollateralMultiple: 2, interest: 14, utilised: 0, risk: CapitalWeightings.m},
  {lender: "Quona Capital", revenueMultiple: 0.25, collateral: 0.4, overcollateralMultiple: 2, interest: 14, utilised: 0, risk: CapitalWeightings.m},
  {lender: "Mynd", revenueMultiple: 0.2, collateral: 0.5, overcollateralMultiple: 0, interest: 10, utilised: 0, risk: CapitalWeightings.s},
  {lender: "KredX", revenueMultiple: 0.2, collateral: 0.5, overcollateralMultiple: 0, interest: 10, utilised: 0, risk: CapitalWeightings.s},
  {lender: "Tradecred", revenueMultiple: 0.2, collateral: 0.5, overcollateralMultiple: 0, interest: 10, utilised: 0, risk: CapitalWeightings.s},
  {lender: "Priorityvendor", revenueMultiple: 0.2, collateral: 0.5, overcollateralMultiple: 0, interest: 10, utilised: 0, risk: CapitalWeightings.s},
  {lender: "Cashflo", revenueMultiple: 0.1, collateral: 0, overcollateralMultiple: 0, interest: 9, utilised: 0, risk: CapitalWeightings.xxs},
  {lender: "M1Xchange", revenueMultiple: 0.1, collateral: 0, overcollateralMultiple: 0, interest: 9, utilised: 0, risk: CapitalWeightings.xxs},
  {lender: "Finagg", revenueMultiple: 0.1, collateral: 0, overcollateralMultiple: 0, interest: 9, utilised: 0, risk: CapitalWeightings.xxs},
  {lender: "Credable", revenueMultiple: 0.1, collateral: 0, overcollateralMultiple: 0, interest: 9, utilised: 0, risk: CapitalWeightings.xxs},
  {lender: "Credlix", revenueMultiple: 0.1, collateral: 0, overcollateralMultiple: 0, interest: 9, utilised: 0, risk: CapitalWeightings.xxs},
  {lender: "Invoicemart", revenueMultiple: 0.1, collateral: 0, overcollateralMultiple: 0, interest: 9, utilised: 0, risk: CapitalWeightings.xxs},
], window.nlenders)

export const equityLender: CreditLine =
  {lender: "Equity", revenueMultiple: 10, collateral: 0, overcollateralMultiple: 0, interest: 6, utilised: 0, risk: CapitalWeightings.xxl}
